import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import Button from "../components/Button/button"
import axios from "axios";
import { injectIntl } from "gatsby-plugin-intl"
const Form = styled.div`
  margin: calc(var(--spacing) * 2) 0;

  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`

const FormLabel = styled.label`
  font-family: var(--serif);
  font-size: var(--h5);
  margin-bottom: var(--spacing);
  display: inline-block;
`

const FormInput = styled.input`
  height: 50px;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mailSent: false,
      error: null
    };
  }

  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: "post",
      url: 'https://georges-enesco.org/api/contact/index.php',
      headers: { "content-type": "application/json" },
      data: this.state
    })
      .then(result => {
        if (result.data.sent) {
          this.setState({
            mailSent: result.data.sent
          });
          this.setState({ error: false });
        } else {
          this.setState({ error: true });
        }
      })
      .catch(error => this.setState({ error: error.message }));
  };
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - namve of the field
   * @return void
   */
  handleChange = (e, field) => {
    let value = e.target.value;
    let updateValue = {};
    updateValue[field] = value;
    this.setState(updateValue);
  };


  render() {
    const {intl} = this.props
    return (

    <>
      <Seo
        title={intl.formatMessage({id: "contact_title"})}
      />
      <Layout>
        <h6> ‎ </h6>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({id: "about_ge1"})}</p>
        <p>{intl.formatMessage({id: "about_ge2"})}</p>
        <p>{intl.formatMessage({id: "about_ge3"})}</p>
        <p>{intl.formatMessage({id: "about_ge4"})}<a href="mailto:info@georges-enesco.org">info@georges-enesco.org</a></p>
        <Form>
          <form name="contact" onSubmit={e => this.handleFormSubmit(e)}>
            <p>
              <FormLabel for="name">{intl.formatMessage({id: "contact_name"})}</FormLabel>
              <FormInput type="text" name="name" onChange={e => this.handleChange(e, 'name')}/>
            </p>
            <p>
              <FormLabel for="email">{intl.formatMessage({id: "contact_email"})}</FormLabel>
              <FormInput type="email" name="email" onChange={e => this.handleChange(e, 'email')}/>
            </p>
            <p>
              <FormLabel for="message">{intl.formatMessage({id: "contact_message"})}</FormLabel>
              <FormTextArea type="text" name="message" onChange={e => this.handleChange(e, 'msg')}/>
            </p>
            <p>
              { !this.state.mailSent ? <Button className="btn-link" text={intl.formatMessage({id: "contact_title"})} type="submit"/> : null }
            </p>
            <p>
              {this.state.mailSent && <h4>Mesaj trimis!</h4>}
            </p>
          </form>
        </Form>
      </Layout>
    </>
    )
  }
}

export default injectIntl(ContactPage)
